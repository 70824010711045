function getFeaturedCardBlogData(
  cardBlogsData = [],
  { titleClass = "", hasText = true, idString = "featured-article-card-0", textAttr = "text", useSlugOnId = false } = {}
) {
  return cardBlogsData.map((card, i) => ({
    text: hasText ? card[textAttr] : null,
    title: card.title,
    titleClass: titleClass,
    image: {
      imgVariable: card.imgVariable,
      altText: card.imageAlt
    },
    url: card.slug,
    id: `${idString}${useSlugOnId ? card.slug.split("/").pop() : i + 1}`
  }));
}

export default getFeaturedCardBlogData;
