import React from "react";

import Icon from "../custom-widgets/icon";
import NotificationAlert from "../notifications/notification-alert";

const ReturningBorrowerLink = (props) => {
  const getAlertBody = (loanType) => {
    return (
      <>
        Already started your application?{" "}
        <a
          href="https://www.wafdbank.com/personal-banking/home-loans/mortgage-login"
          className="text-decoration-none"
          id="returning-borrower-link"
        >
          Return to {loanType}{" "}
          <nobr>
            application
            <Icon name="arrow-right" class="ml-1" />
          </nobr>
        </a>
      </>
    );
  };

  return (
    <NotificationAlert
      id="returning-borrower-alert"
      class="mb-0"
      type="info"
      bodyContent={getAlertBody(props.loanType)}
    />
  );
};

export default ReturningBorrowerLink;

ReturningBorrowerLink.defaultProps = {
  loanType: "Home Loan"
};
